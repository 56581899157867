const { anchorate } = require("anchorate");
const jQuery = require("jquery");

exports.onRouteUpdate = () => {
  anchorate();  
};

const updateHubspotForms = () => {
  if (!window.hbspt) {
    setTimeout(updateHubspotForms, 1500);
  } else {
    [...document.getElementsByTagName("hubspot")].forEach((el) =>
      window.hbspt.forms.create({
        portalId: el.getAttribute("portal-id"),
        formId: el.getAttribute("form-id"),
        target: `#${el.getAttribute("id")}`,
      })
    );
  }
};

exports.onClientEntry = () => {
  // hubspot requires jQuery for its selectors, we provide jquery here to get around this requirement
  // @ts-ignore
  window.jQuery = jQuery;

  updateHubspotForms();
 
};

