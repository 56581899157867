// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-checkin-tsx": () => import("./../../../src/pages/checkin.tsx" /* webpackChunkName: "component---src-pages-checkin-tsx" */),
  "component---src-pages-contact-us-success-tsx": () => import("./../../../src/pages/contact-us-success.tsx" /* webpackChunkName: "component---src-pages-contact-us-success-tsx" */),
  "component---src-pages-devin-story-tsx": () => import("./../../../src/pages/devin-story.tsx" /* webpackChunkName: "component---src-pages-devin-story-tsx" */),
  "component---src-pages-education-tsx": () => import("./../../../src/pages/education.tsx" /* webpackChunkName: "component---src-pages-education-tsx" */),
  "component---src-pages-faleula-story-tsx": () => import("./../../../src/pages/faleula-story.tsx" /* webpackChunkName: "component---src-pages-faleula-story-tsx" */),
  "component---src-pages-feature-updates-tsx": () => import("./../../../src/pages/feature-updates.tsx" /* webpackChunkName: "component---src-pages-feature-updates-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kevin-story-tsx": () => import("./../../../src/pages/kevin-story.tsx" /* webpackChunkName: "component---src-pages-kevin-story-tsx" */),
  "component---src-pages-mission-tsx": () => import("./../../../src/pages/mission.tsx" /* webpackChunkName: "component---src-pages-mission-tsx" */),
  "component---src-pages-newsroom-tsx": () => import("./../../../src/pages/newsroom.tsx" /* webpackChunkName: "component---src-pages-newsroom-tsx" */),
  "component---src-pages-our-story-tsx": () => import("./../../../src/pages/our-story.tsx" /* webpackChunkName: "component---src-pages-our-story-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/privacy-policy/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-proactive-support-tsx": () => import("./../../../src/pages/proactive-support.tsx" /* webpackChunkName: "component---src-pages-proactive-support-tsx" */),
  "component---src-pages-psychosocial-hazards-tsx": () => import("./../../../src/pages/psychosocial-hazards.tsx" /* webpackChunkName: "component---src-pages-psychosocial-hazards-tsx" */),
  "component---src-pages-psychosocial-risk-register-tsx": () => import("./../../../src/pages/psychosocial-risk-register.tsx" /* webpackChunkName: "component---src-pages-psychosocial-risk-register-tsx" */),
  "component---src-pages-psychosocial-survey-tsx": () => import("./../../../src/pages/psychosocial-survey.tsx" /* webpackChunkName: "component---src-pages-psychosocial-survey-tsx" */),
  "component---src-pages-request-a-demo-tsx": () => import("./../../../src/pages/request-a-demo.tsx" /* webpackChunkName: "component---src-pages-request-a-demo-tsx" */),
  "component---src-pages-research-papers-tsx": () => import("./../../../src/pages/research-papers.tsx" /* webpackChunkName: "component---src-pages-research-papers-tsx" */),
  "component---src-pages-research-tsx": () => import("./../../../src/pages/research.tsx" /* webpackChunkName: "component---src-pages-research-tsx" */),
  "component---src-pages-signup-expired-tsx": () => import("./../../../src/pages/signup/expired.tsx" /* webpackChunkName: "component---src-pages-signup-expired-tsx" */),
  "component---src-pages-signup-index-tsx": () => import("./../../../src/pages/signup/index.tsx" /* webpackChunkName: "component---src-pages-signup-index-tsx" */),
  "component---src-pages-signup-success-tsx": () => import("./../../../src/pages/signup/success.tsx" /* webpackChunkName: "component---src-pages-signup-success-tsx" */),
  "component---src-pages-skodel-discover-tsx": () => import("./../../../src/pages/skodel-discover.tsx" /* webpackChunkName: "component---src-pages-skodel-discover-tsx" */),
  "component---src-pages-skodel-report-tsx": () => import("./../../../src/pages/skodel-report.tsx" /* webpackChunkName: "component---src-pages-skodel-report-tsx" */),
  "component---src-pages-skodel-strategy-tsx": () => import("./../../../src/pages/skodel-strategy.tsx" /* webpackChunkName: "component---src-pages-skodel-strategy-tsx" */),
  "component---src-pages-skodel-support-tsx": () => import("./../../../src/pages/skodel-support.tsx" /* webpackChunkName: "component---src-pages-skodel-support-tsx" */),
  "component---src-pages-stephen-story-tsx": () => import("./../../../src/pages/stephen-story.tsx" /* webpackChunkName: "component---src-pages-stephen-story-tsx" */),
  "component---src-pages-technology-tsx": () => import("./../../../src/pages/technology.tsx" /* webpackChunkName: "component---src-pages-technology-tsx" */),
  "component---src-pages-wellbeing-planner-tsx": () => import("./../../../src/pages/wellbeing-planner.tsx" /* webpackChunkName: "component---src-pages-wellbeing-planner-tsx" */),
  "component---src-pages-who-we-serve-tsx": () => import("./../../../src/pages/who-we-serve.tsx" /* webpackChunkName: "component---src-pages-who-we-serve-tsx" */),
  "component---src-pages-workplaces-tsx": () => import("./../../../src/pages/workplaces.tsx" /* webpackChunkName: "component---src-pages-workplaces-tsx" */)
}

